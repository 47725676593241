import React from 'react';
import logo from './logo.svg';
import './App.css';
import Figoweb  from './Figoweb/Figoweb';
import { BrowserRouter as Router, Routes, Route, useNavigate} from 'react-router-dom';

function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<Figoweb/>} />
      </Routes>
    </Router>
  );
}

export default App;

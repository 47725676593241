import React, { useState, useEffect } from 'react';
import Preloader from './preloader';
import LaunchHeader from './launch-header';
import SiteHeader from './site-header'; // Ensure the correct path to Preloader component
import MainScreen from './mainscreen';
import ProductSection from './products-section';
import Roadmap from './roadmap';
import './css/style.min.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Partners from './partners';
import Footer from './footer';

const FigoWeb = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    AOS.init({
      duration: 1000, // Global duration
      delay: 1000, // Global delay
      once: true, // Whether animation should happen only once - while scrolling down
    });
  }, []);

  return (
    <div>
        <div className='body'>
          {/* Content of the main page when loading is complete */}
          <LaunchHeader />
          <SiteHeader />
           <MainScreen />
          <ProductSection />
          <Roadmap />
          <Partners />
          <Footer />
          

          {/* Add other components or content here */}
        </div>
    </div>
  );
};

export default FigoWeb;

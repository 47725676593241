import React, { useEffect } from 'react';
import 'aos/dist/aos.css'; // Import AOS styles
import AOS from 'aos'; // Import AOS

const Roadmap = () => {
    useEffect(() => {
        AOS.init({
            duration: 1000, // Duration of animations
            easing: 'ease-in-out', // Easing function
            once: true, // Whether animation should happen only once - while scrolling down
        });
    }, []);

    return (
        <div>
            <section className="roadmap" id="roadmap">
                <div className="container">
                    <div 
                        className="roadmap__title ui-title" 
                        data-aos="fade-up" 
                        data-aos-duration="1000"
                    >
                        Roadmap
                    </div>
                    <div className="roadmap__wrapper">
                        <div className="roadmap__img">
                            <div 
                                className="roadmap-phase roadmap-phase-1" 
                                data-aos="fade-up" 
                                data-aos-duration="1000"
                            >
                                <h3 className="roadmap-phase__title">
                                    Phase 1
                                </h3>
                                <div 
                                    className="roadmap-phase__text ui-format" 
                                    data-aos="fade-up" 
                                    data-aos-duration="1000"
                                >
                                    <ul> 
                                        <li>Development of ideas</li>
                                        <li>Team recruitment</li>
                                        <li>Expansion of the team</li>
                                        <li>Whitepaper v1</li>
                                        <li>Website v1</li>
                                        <li>Figo App (test version) Released</li>
                                        <li>Smart-contract development</li>
                                        <li>Audit by BlockSAFU</li>
                                        <li>KYC by Pinksale</li>
                                        <li>Pre-launch Marketing Campaign</li>
                                        <li>Feedback from Beta Testers</li>
                                        <li>Figo App v1 Released</li>
                                        <li>Airdrop Points Program Begins</li>
                                    </ul>
                                </div>
                            </div>
                            <div 
                                className="roadmap-phase roadmap-phase-2" 
                                data-aos="fade-up" 
                                data-aos-duration="1000"
                            >
                                <h3 className="roadmap-phase__title">
                                    Phase 2
                                </h3>
                                <p>Please note that these plans are not in exact order and are subject to change as we will always look for better ways to serve our community's interests</p>
                                <div 
                                    className="roadmap-phase__text ui-format" 
                                    data-aos="fade-up" 
                                    data-aos-duration="1000"
                                >
                                    <ul>
                                        <li>Figo App v2 Released</li>
                                        <li>Introduction of NFT for pfp</li>
                                        <li>Premium Users intro</li>
                                        <li>In App NFT Market Place </li>
                                        <li>Secret Rooms integration</li>
                                        <li>Voice and Video Calls </li>
                                        <li>Groups and Channels Integration </li>
                                        <li>Timeline Integrations</li>
                                        <li>Presale on Pinksale</li>
                                        <li>Raydium listing</li>
                                        <li>Dextools / Dexview / Dexscreener Trendings</li>
                                        <li>BuyBots Trendings</li>
                                        <li>CMC / CG / Solscan Trends</li>
                                        <li>MEXC Listings</li>
                                        <li>Certik Audit</li>
                                        <li>Google Ads</li>
                                        <li>WW Marketing Campaign</li>
                                        <li>Coinzilla / A-Ads Banner Ad</li>
                                        <li>BingX Listing</li>
                                    </ul>
                                </div>
                            </div>
                            <div 
                                className="roadmap-phase roadmap-phase-3" 
                                data-aos="fade-up" 
                                data-aos-duration="1000"
                            >
                                <h3 className="roadmap-phase__title">
                                    Phase 3
                                </h3>
                                <div 
                                    className="roadmap-phase__text ui-format" 
                                    data-aos="fade-up" 
                                    data-aos-duration="1000"
                                >
                                    <ul>
                                        <li>Influencers On-Boarding</li>
                                        <li>Figo App v2</li>
                                        <li>Contract Security Improvement</li>
                                        <li>Certik Bug Bounty</li>
                                        <li>Website &amp; Whitepaper v2</li>
                                        <li>Partnerships with Launchpads</li>
                                        <li>HTX / KuCoin Listing</li>
                                        <li>Gate / OKX Listing</li>
                                        <li>Partnerships with CEXs</li>
                                        <li>Perpetural Trading (Bybit)</li>
                                        <li>Major CEX Listings</li>
                                        <li>Airdrop </li>
                                        <li>Premium Users incentives </li>
                                        <li>Loyal Community members reward </li>
                                        <li>Continuous App Development and Improvement</li>
                                        <li>Celebrate more users than web2 apps (hopefully)</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
           
        </div>
    );
};

export default Roadmap;

import React from 'react';
import Fire from './img/Fire.svg';
import Net from './img/Net.svg';
import Runnerp from './img/Runnerp.svg';
import Apenft from './img/Apenft.svg';
import Solanalogo from './img/Solanalogo.svg';
import Chatearn from './img/Chatearn.svg';
import Star from './img/Star.svg';
import Loader from './img/Loader.svg';

const ProductSection = () => {
  return (
    <div>
      <section className="products container">
        <div className="">
          <div className="products__net-background">
            <h2 className="products__title" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100" data-aos-anchor=".products">
              WHAT&nbsp;FIGO&nbsp;IS
            </h2>
            <img src={Loader} className="loader" />
            <div className="products__text" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200" data-aos-anchor=".products">
              Figo is the very first Multidimensional Social Media App built on and for the Solana Ecosystem.
              Figo aims to bring you the thrill of 5 different social media apps in one on your favorite blockchain technology.
            </div>
          </div>
          <div className="products__fire-background">
            <img src={Runnerp} className="runnerp" />
          </div>
          <div className="">
            <div className="products__items">
              <div className="product-item" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="300" data-aos-anchor=".products">
                <div className="product-item__top-icon">
                  <svg width="58" height="60" viewBox="0 0 58 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M21.5 24.5C18.3 27.7 5.83333 29.5 0 30C5.5 30 17.5 31 21.5 35C25.5 39 27.8333 53 28.5 59.5C28.5 53.8333 30 41 36 35C42 29 52.5 31 57.5 30C52.5 29 40.4 28.9 36 24.5C31.6 20.1 29.1667 6.33333 28.5 0C27.5 6.83333 24.7 21.3 21.5 24.5Z" fill="#C6A707" />
                  </svg>
                </div>
                <img src={Chatearn} alt="Product 1" className="product-item__img" />
                <h3 className="product-item__title">CHAT AND EARN</h3>
                <p className="product-item__text">                Now you will get to earn points which can be used for various purposes in app and later can be converted into $FIGO tokens for just chatting your friends and family</p>
                <a href="#" className="product-item__btn">Learn More</a>
              </div>
              <div className="product-item" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="400" data-aos-anchor=".products">
                <img src={Apenft} alt="Product 2" className="product-item__img" />
                <h3 className="product-item__title">NFT FOR PFP</h3>
                <p className="product-item__text">Now your NFTs can have real use. regular jpegs will not be allowed for pfps. Only NFTs on solana chain will be allowed for pfps. now your nft just got more valuable.</p>
                <a href="#" className="product-item__btn">Learn More</a>
              </div>
              <div className="product-item" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="500" data-aos-anchor=".products">
                <img src={Solanalogo} alt="Product 3" className="product-item__img" />
                <h3 className="product-item__title">DECENTRALIZATION</h3>
                <p className="product-item__text">All conversations are end to end encrypted and are stored on the solana blockchain making them tamper proof and out of the reach of all third parties. True decentralization</p>
                <a href="#" className="product-item__btn">Learn More</a>
              </div>
            </div>
          </div>
          <div className="products-text" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="600" data-aos-anchor=".products">
            <div className="products-text__text">
              Experience the future of social media with Figo, where every interaction is an opportunity.
            </div>
            <div className="products-text__tip">
              <span>Get started now by exploring our unique features.</span>
        
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ProductSection;



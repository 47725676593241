import React, { useState } from 'react';
import Logo from './img/Logo.svg';
import Runnerw from './img/Runnerw.svg';
import Menu from './img/Menu.svg';

const SiteHeader = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <section 
            className="site-header --launch-header"
            data-aos="fade-down" data-aos-delay="100"
        >
            <div className="container --size-l">
                <div className="site-header__wrapper">
                    <a 
                        className="site-header__logo"
                        data-aos="fade-down" 
                        data-aos-delay="100"
                    >
                        <img src={Runnerw} alt="runner logo" className="site-header__logo" />
                    </a>
                    <button 
                        className="site-header__menu-btn"
                        onClick={toggleMenu}
                        
                    >
                        <img src={Menu} alt="Menu icon" />
                    </button>
                    <ul 
                        className={`site-header__menu ${isMenuOpen ? 'is-open' : ''}`}
                        
                    >
                        <li className="site-header__menu-item site-header__menu-item--dropdown">
                        <a href="#">
                                Product
                                <i className="fas fa-chevron-down dropdown-icon"></i>
                            </a>
                            <div className="menu-dropdown">
                                <ul className="menu-dropdown__ul">
                                    <li className="menu-dropdown__li --status">
                                        <a href="https://app.figoapp.xyz">Figo Dapp</a>
                                    </li>
                                    {/* Add other menu items */}
                                </ul>
                            </div>
                        </li>
                        <li className="site-header__menu-item"><a href="/">FIGO</a></li>
                        <li className="site-header__menu-item"><a href="#roadmap">Roadmap</a></li>
                        <li className="site-header__menu-item"><a href="#tokenomics">Figonomics</a></li>
                        <li className="site-header__menu-item"><a href="#Docs">Docs</a></li>
                    </ul>
                </div>
            </div>
        </section>


    );
}

export default SiteHeader;



import React from 'react';
import Logo from './img/Logo.svg';
import AOS from 'aos';
import 'aos/dist/aos.css';
// Assuming you have a CSS file for styles

const LaunchHeader = () => {

  return (
    <section 
      className="launch-header aos-init aos-animate" 
      data-aos="fade-down" 
      data-aos-duration="1000" 
      data-aos-delay="1000"
    >
      <div className="container --size-l">
        <div className="launch-header__container">
          <div 
            className="launch-header__wrapper" 
            data-aos="fade-left" 
            data-aos-duration="1000"
          >
            <div className="launch-header__title">
              <img 
                src={Logo} 
                alt="cloud logo" 
                className="launch-header__img --2" 
              />
            </div>
            <div className="launch-header__content">
              <div 
                className="launch-header__info" 
                data-aos="fade-down" 
                data-aos-duration="1000"
              >
                <div 
                  className="launch-header__text"
                >
                  Never Miss An Update!
                </div>
                <div 
                  className="launch-header__btns" 
                  data-aos="fade-up" 
                  data-aos-duration="1000"
                >
                  <a 
                    href="https://t.me/figoapp_announcement" 
                    target="_blank" 
                    className="launch-header__btn"
                    rel="noopener noreferrer"
                  >
                    Follow
                    <svg 
                      className="launch-header__btn-icon" 
                      width="17" 
                      height="14" 
                      viewBox="0 0 17 14" 
                      fill="none" 
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path 
                        fillRule="evenodd" 
                        clipRule="evenodd" 
                        d="M12.8691 13.8454C13.0945 14.005 13.385 14.0449 13.644 13.9469C13.903 13.8482 14.0934 13.627 14.1508 13.3589C14.7591 10.5001 16.2347 3.2642 16.7884 0.663704C16.8304 0.467704 16.7604 0.264004 16.6064 0.133104C16.4524 0.00220379 16.2389 -0.0355963 16.0485 0.0351037C13.1134 1.1215 4.07428 4.513 0.379679 5.8801C0.145179 5.9669 -0.0074215 6.1923 0.000278497 6.4394C0.0086785 6.6872 0.175279 6.9021 0.415379 6.9742C2.07228 7.4698 4.24718 8.15927 4.24718 8.15927C4.24718 8.15927 5.26358 11.2288 5.79348 12.7898C5.85998 12.9858 6.01328 13.1398 6.21558 13.193C6.41718 13.2455 6.63278 13.1902 6.78328 13.0481C7.63449 12.2445 8.95049 11.002 8.95049 11.002C8.95049 11.002 11.4509 12.8353 12.8691 13.8454ZM5.16208 7.7715L6.33738 11.6481L6.59848 9.19317C6.59848 9.19317 11.1394 5.0975 13.728 2.763C13.8036 2.6944 13.8141 2.5796 13.7511 2.4991C13.6888 2.4186 13.574 2.3997 13.4879 2.4543C10.4877 4.3702 5.16208 7.7715 5.16208 7.7715Z" 
                        fill="black"
                      ></path>
                    </svg>
                  </a>
                  <a 
                    href="https://x.com/figo_on_sol" 
                    target="_blank" 
                    className="launch-header__btn"
                    rel="noopener noreferrer"
                  >
                    Follow
                    <svg 
                      className="launch-header__btn-icon" 
                      width="18" 
                      height="18" 
                      viewBox="0 0 18 18" 
                      fill="none" 
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path 
                        d="M10.3533 7.37201L16.8295 0H15.2954L9.6697 6.3997L5.17983 0H0L6.79106 9.6784L0 17.4081H1.53412L7.47115 10.6484L12.2138 17.4081H17.3936M2.08782 1.13291H4.44465L15.2942 16.3309H12.9368" 
                        fill="black"
                      ></path>
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LaunchHeader;


import { useState } from 'react';
import Runnerp from './img/Runnerp.svg'

const Footer = () => {

    return (
        <div>
    <footer className="site-footer">
    <div className="container">
        <div className="site-footer__wrapper">
            <div className="site-footer__side">
                <div className="site-footer__media">
                    <div className="site-footer__logo">
                        <a href="/">
                        <img src={Runnerp} />
                        </a>
                    </div>
                    <div className="site-footer__text">Fly High Connect Fast</div>
                </div>
                <div className="social-links display-inline-flex --gap --justify-center">
                    <a className="ui-img-icon --btn --dark" href="https://x.com/figo_on_sol" target="_blank">
                        <div className="ui-img-icon__wrapper">
                            <svg className="ui-img-icon__icon ui-svg-icon" viewBox="0 0 10 10" fill="white" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.95236 4.23481L9.67568 0H8.79369L5.55935 3.67627L2.97801 0H0L3.90435 5.55971L0 10H0.882003L4.29535 6.11692L7.02201 10H10M1.20034 0.650793H2.55534L8.793 9.38121H7.43768"></path>
                            </svg>
                        </div>
                    </a>
                    <a className="ui-img-icon --btn --dark" href="https://t.me/figoapp_announcement" target="_blank">
                        <div className="ui-img-icon__wrapper">
                            <svg className="ui-img-icon__icon ui-svg-icon" viewBox="0 0 12 10" fill="white" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10.2428 0.45668C8.76633 1.0682 2.43537 3.69056 0.686134 4.40528C-0.486986 4.86308 0.199774 5.29232 0.199774 5.29232C0.199774 5.29232 1.20117 5.63564 2.05965 5.89316C2.91801 6.15068 3.37581 5.8646 3.37581 5.8646L7.41021 3.14636C8.84085 2.17352 8.49754 2.97464 8.1541 3.31808C7.41022 4.06208 6.17985 5.23508 5.14977 6.17936C4.69197 6.57992 4.92081 6.92324 5.12121 7.09496C5.86509 7.72448 7.8967 9.01208 8.01106 9.09788C8.6155 9.5258 9.80446 10.1418 9.9853 8.84036L10.7006 4.34816C10.9296 2.83172 11.1584 1.42964 11.187 1.02908C11.2729 0.0561202 10.2428 0.45668 10.2428 0.45668Z"></path>
                            </svg>
                        </div>
                    </a>
                    <a className="ui-img-icon --btn --dark" href="https://docs.figoapp.xyz" target="_blank">
                        <div className="ui-img-icon__wrapper">
                            <svg className="ui-img-icon__icon ui-svg-icon" viewBox="0 0 15 10" fill="white" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6.84439 8.33654C7.06823 8.33654 7.25044 8.51874 7.25044 8.74239C7.25044 8.96623 7.06823 9.14824 6.84439 9.14824C6.62054 9.14824 6.43853 8.96603 6.43853 8.74239C6.43833 8.51874 6.62054 8.33654 6.84439 8.33654ZM13.2195 5.82231C12.9956 5.82231 12.8134 5.6401 12.8134 5.41645C12.8134 5.19261 12.9956 5.0104 13.2195 5.0104C13.4433 5.0104 13.6253 5.19261 13.6253 5.41645C13.6255 5.6401 13.4433 5.82231 13.2195 5.82231ZM13.2195 4.15924C12.5263 4.15924 11.9623 4.72311 11.9623 5.41645C11.9623 5.55147 11.9847 5.6859 12.0289 5.81656L7.87597 8.02724C7.64003 7.68721 7.25758 7.48577 6.84399 7.48577C6.36478 7.48577 5.928 7.75997 5.71664 8.18843L1.98564 6.22142C1.59149 6.01423 1.29647 5.3649 1.32779 4.77407C1.34405 4.46576 1.45052 4.22665 1.6127 4.13425C1.7156 4.07577 1.83952 4.08092 1.97097 4.14972L1.99595 4.16281C2.98432 4.68366 6.21985 6.38777 6.35606 6.45101C6.56622 6.54816 6.6832 6.58762 7.04127 6.4179L13.7292 2.93969C13.8272 2.90262 13.9416 2.80884 13.9416 2.66608C13.9416 2.46821 13.737 2.3903 13.7364 2.3903C13.3561 2.20789 12.7714 1.93408 12.201 1.66721C10.982 1.0964 9.6005 0.449652 8.9938 0.131829C8.46998 -0.142375 8.04847 0.0888048 7.97312 0.135596L7.827 0.207965C5.09646 1.55836 1.44219 3.36815 1.23401 3.49464C0.861466 3.72126 0.630684 4.17292 0.600944 4.73362C0.554351 5.62245 1.00779 6.54896 1.65593 6.88899L5.60105 8.92361C5.68987 9.53923 6.21984 10 6.84419 10C7.53099 10 8.09089 9.44644 8.1012 8.76202L12.4462 6.40719C12.6665 6.57929 12.9397 6.67386 13.2195 6.67386C13.9126 6.67386 14.4767 6.10979 14.4767 5.41665C14.4767 4.72351 13.9126 4.15924 13.2195 4.15924Z"></path>
                            </svg>
                        </div>
                    </a>
                </div>
            </div>
            <div className="site-footer__side">
                <div className="site-footer__menu-wrapper">
                    <div className="site-footer__col-3">
                        <div className="site-footer__title color">Products</div>
                        <ul className="site-footer__ul">
                            <li className="site-footer__li"><a href="https://app.figoapp.xyz">Figo Dapp</a></li>
                            <li className="site-footer__li"><a href="/">$FIGO token</a></li>
                        </ul>
                    </div>
                    <div className="site-footer__col-3">
                        <div className="site-footer__title color">Website</div>
                        <ul className="site-footer__ul">
                            <li className="site-footer__li"><a href="https://docs.figoapp.xyz/testing" target="_blank">Beta Testing</a></li>
                            <li className="site-footer__li"><a href="https://docs.figoapp.xyz/figoapp-ecosystem/point-program" target="_blank">Point program</a></li>
                            <li className="site-footer__li"><a href="https://docs.figoapp.xyz" target="_blank">Docs</a></li>
                            <li className="site-footer__li"><a>Support</a></li>
                        </ul>
                    </div>
                    <div className="site-footer__col-3">
                        <div className="site-footer__title color">Docs</div>
                        <ul className="site-footer__ul">
                            <li className="site-footer__li"><a>Contract</a></li>
                            <li className="site-footer__li"><a>Audit</a></li>
                            <li className="site-footer__li"><a>KYC</a></li>
                            <li className="site-footer__li"><a>Privacy Policy</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div className="site-footer__divider"></div>
        <div className="site-footer__policy">
            Copyright © 2024 Figo. - All rights reserved.
        </div>
    </div>
</footer>
</div> );
}

export default Footer;
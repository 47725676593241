import { useState } from 'react';
import Quoteup from './img/Quoteup.svg';
import Quotedown from './img/Quotedown.svg';

const Partners = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <div>
            <section id="partners" className="partners aos-init" data-aos="fade-up" data-aos-duration="1000">
                <div className="container">
                    <img src={Quoteup} className='partners__img' alt="Quote up" />
                    <div className="partners__text">
                        Figo is the very first Multidimensional Social Media App built on and for the Solana Ecosystem.
                        Figo aims to onboard as many non-crypto users as possible by providing them with a means to communicate daily and earn points
                    </div>
                    <img src={Quotedown} className='partners__img' alt="Quote down" />
                </div>
            </section>
        </div>
    );
}

export default Partners;
